@import "./imports/variables";

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  font-size: 14px;
  background-color: $seafoam-opacity-30;
  font-family: sans-serif;
}