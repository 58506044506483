@import "../../../../Sass/imports";

.buttons {
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: .5rem;
  max-width: 60rem;

  &__button {
    border: .2rem solid $ocean;
    color: $ocean;
    padding: 1rem 0rem;
    text-align: center;
    font-size: 2.2rem;
    transition: .1s ease-out;
    border-radius: .3rem;

    &:active {
      background-color: $ocean;
      color: $white;
      transform: scale(.95);
    }
  }
}