@import "../../../Sass/imports";

.ed-input {
  height: max-content;

  &__fields {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(4, 1fr);
    gap: .5rem;
    max-width: 60rem;
    margin: 0rem auto;

    &.header {
      margin: 2.7rem auto 0rem;
      font-weight: bold;
    }

    &__field {
      width: 100%;
      border: none;
      padding: 1.4rem 0rem;
      text-align: center;
      transition: 0.15s ease-out;
      pointer-events: none;
      font-size: 1.6rem;
      color: $ocean;

      &.focus {
        box-shadow: 0rem 0rem 0rem .2rem $ocean;
      }
    }
  }
}