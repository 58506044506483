@import "../../Sass/imports";

.editor {
  width: 90vw;
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      height: 6rem;
      font-size: 2.8rem;
      display: flex;
      align-items: center;
      color: $ocean;
      font-weight: bold;
    }

    &__doc-sr {
      color: $ocean;
    }

    &__links {
      display: flex;
      gap: 1.5rem;

      &__link {
        text-decoration: none;
        color: $ocean;
        font-size: 1.6rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__doc-details {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
    color: $ocean;

    p {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      span {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
}