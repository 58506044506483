$aqua: #003b46;
$ocean: #07575b;
$wave: #66a5ad;

$seafoam: #c4dfe6;
$seafoam-opacity-30: rgba(#c4dfe6, .3);
$seafoam-opacity-50: rgba(#c4dfe6, .5);

$white: #fafafa;

$black-opacity-30: rgba(0, 0, 0, .3);